<template>
    <div style="height: 100%!important;width:100%;">
        <h5-header @ok="headOk"></h5-header>
        <div class="div2"  :style="{backgroundImage:'url('+webObj.bannerImage+')'}">
<!--            <img class="top_img2" :src="webObj.bannerImage"/>-->
        </div>

        <div class="div-main2" v-if="topList.length>0">
            <!--                <div class="news-title">{{item.navName}}</div>-->
            <div class="div-main-item-list">
                <div class="news-list" style="flex:1;" v-for="(item,index) of topList" :key="index"
                     @click="navArticle(item.navCode,item.id)">
                    <div class="news-item-1" v-if="topList.length===1">
                        <img class="news-img" :src="item.imageSrc">
                        <div class="news-item-content-1">
                            <div class="news-subTitle">{{item.title}}</div>
                            <div class="news-content" v-text="item.content"></div>
                            <div class="news-time">{{item.createTime}}</div>
                        </div>
                    </div>
                    <div class="news-item" v-else>
                        <img class="news-img" :src="item.imageSrc">
                        <div class="news-subTitle">{{item.title}}</div>
                        <div class="news-content" v-text="item.content"></div>
                        <div class="news-time">{{item.createTime}}</div>
                    </div>

                </div>

            </div>
        </div>

        <div class="div-main2" style="background-color: #f6f7f8;" v-if="list1.length>0">
            <div class="notice-title2">通知公告</div>
            <div class="notice-list" :style="{justifyContent: list1.length===1?'flex-start':'space-around'}">
                <div class="notice-item" >
                    <!--                    <div class="notice-head">-->
                    <!--                        <span class="h1">{{item.navName}}</span>-->
                    <!--                        <span class="h2" @click="moreAction(item.navCode)">更多></span>-->
                    <!--                    </div>-->
                    <div class="notice-subitem-body" v-for="(item,index) of list1" :key="index"
                         @click="navArticle(item.navCode,item.id)">
                        <div style="width: 33vh;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                            <span class="notice-cate">[{{item.navName}}]{{item.title}}</span>
                            <!--                            <span class="" style="">{{subItem.createTime}}</span>-->
                        </div>
                        <span style="font-size: 12px;width:20vw">{{item.createTime}}</span>
                    </div>
                </div>

                <div class="notice-item" v-if="list2.length>0" >
                    <!--                    <div class="notice-head">-->
                    <!--                        <span class="h1">{{item.navName}}</span>-->
                    <!--                        <span class="h2" @click="moreAction(item.navCode)">更多></span>-->
                    <!--                    </div>-->
                    <div class="notice-subitem-body" v-for="(item,index) of list2" :key="index"
                         @click="navArticle(item.navCode,item.id)">
                        <div style="width: 33vh;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                            <span class="notice-cate">[{{item.navName}}]{{item.title}}</span>
                            <!--                            <span class="" style="">{{subItem.createTime}}</span>-->
                        </div>
                        <span style="font-size: 12px;width:20vw">{{item.createTime}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="div-service2" v-if="serviceList.length>0">
            <img class="service-bg" src="../assets/img/bg_f5.png"/>
            <div class="service-wrap">
                <div style="display: flex;flex-direction:column;align-items: center;">
                    <div class="service-title">相关服务</div>
                    <div class="service-itemlist">
                        <a :key="index" v-for="(item,index) of serviceList" :href="item.linkUrl" target="_blank"
                           class="item-link"
                        >
                            <!--                            <img style="width:43px;height: 43px;" :src="item.url">-->
                            <div style="display: flex;flex-direction: column;align-items: center;width:120px">
                                <i :class="'fa '+item.icon" style="font-size:40px;"></i>
                                <span style="font-size: 14px;margin-top: 8px;">{{item.moduleName}}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <h5-footer></h5-footer>
    </div>
</template>

<script>
    import H5Header from "./H5Header";
    import H5Footer from "./H5Footer";

    export default {
        name: 'H5Index',
        components: {H5Header, H5Footer},
        data: function () {
            return {

                serviceList: [
                    // {name: '网上报名', url: '../assets/img/service1.png'},
                    // {name: '报考成绩查询', url: '../assets/img/service2.png'},
                    // {name: '录取查询', url: '../assets/img/service3.png'},
                    // {name: '学生选课', url: '../assets/img/service4.png'},
                    // {name: '统考成绩查询', url: '../assets/img/service5.png'},
                    // {name: '外语查询', url: '../assets/img/service6.png'},
                    // {name: '学籍查询', url: '../assets/img/service7.png'},
                    // {name: '学籍异动', url: '../assets/img/service8.png'},
                ],
                webObj: {},
                topList: [],
                list1: [],
                list2: [],
                current:1
            }
        },
        created() {
            this.getList()
            this.getServiceList();
        },
        methods: {
            _isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            },
            headOk(e) {
                this.webObj = e
            },
            navArticle(navCode, articleId) {
                if(this._isMobile()){
                    this.$router.push({path: '/h5_article?id=' + navCode + "&articleId=" + articleId})
                }else{
                    this.$router.push({path: '/article?id=' + navCode + "&articleId=" + articleId})
                }
            },
            // moreAction(navCode) {
            //     this.$router.push({path: '/article?id=' + navCode})
            // },
            getServiceList() {
                this.$axios.get("/front/company/webServiceList").then(res => {
                    // console.log(res.data)
                    if (res.data.success) {
                        this.$nextTick(() => {
                            this.serviceList = res.data.result
                        })
                    }

                });
            },
            getList() {
                this.$axios.get("/front/company/webModuleIndex").then(res => {
                    if (res.data.success) {
                        let result = res.data.result
                        this.topList = result.topList
                        this.list1 = result.list1
                        this.list2 = result.list2
                        this.current=1
                        // this.picAndDescList = result.picAndDesc
                        // this.list = result.list
                        // console.log(this.list)
                    }
                });
            },
            prevTopList(){
                let current=this.current-1
                this.$axios.get("/front/company/getTopList?current="+current).then(res => {
                    console.log(res.data)
                    if (res.data.success) {
                        let result = res.data.result
                        let topList = result.topList
                        if (topList.length > 0) {
                            this.topList = topList;
                            if(current<=1){
                                current=1
                            }
                            this.current=current
                        }

                    }
                });
            },
            nextTopList(){
                let current=this.current+1
                this.$axios.get("/front/company/getTopList?current="+current).then(res => {
                    if (res.data.success) {
                        let result = res.data.result
                        let topList = result.topList
                        if (topList.length > 0) {
                            this.topList = topList;
                            this.current=current
                        }

                    }
                });
            }
        }

    }
</script>

<style lang="scss" scoped>
    html, body {
        padding: 0;
        margin: 0;
    }


    .wrap-top {
        display: flex;
        /*margin: 0 90px;*/
        align-items: center;

    }

    .wrap-top .menu {
        font-family: Microsoft YaHei, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        border-bottom: none !important;
    }

    .wrap-top .menu span {
        font-size: 18px !important;
        color: #000000;
    }

    .wrap-top .logo {
        width: 300px;
        height: 49px;
        object-fit: contain;
    }

    .div2 {
        /*height: 360px;*/
        /*position: relative;*/
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        height: 15vh;
        background-position: center;
    }

    /* 图片 */
    .top_img2 {
        /*width: 1920px;*/
        /*height: 360px;*/
        /*position: absolute;*/
        /*left: 50%;*/
        /*top: 0;*/
        width:100%;
    }


    /* 主内容 */
    .div-main2 {
        background: #e8edf1;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;

    }

    .div-main2 .news-title2, .notice-title2 {
        height: 30px;
        line-height: 30px;
        font-size: 30px;
        font-weight: bold;
        color: #0071c1;
        position: relative;
        /*margin-left: 11px;*/
        /*margin-bottom: 20px;*/
        text-align: left;
        margin: auto;


    }

    /* 学院新闻 */
    .div-main2 .div-main-item-list .arrow {
        width: 80px;
        height: 80px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .div-main2 .div-main-item-list .arrow:hover {
        box-shadow: 1px 1px 3px #000000;
    }

    .div-main2 .div-main-item-list .arrow-left {
        background-image: url(../assets/img/arrow_left_light.png);

    }

    .div-main2 .div-main-item-list .arrow-right {
        background-image: url(../assets/img/arrow_right_light.png);

    }

    .div-main2 .div-main-item-list {
        display: flex;
        align-items: center;
        margin: auto;
        width: 100%;
        flex-wrap: wrap;
        .news-list {
            display: flex;
            justify-content: center;

            margin-bottom: 10px;

            .news-item{
                width: 80vw;
                margin: 0 5px;
                text-align: left;
                cursor: pointer;
                .news-img{
                    width: 80vw;
                    height: 53vw;
                    border-radius: 2vw;
                    object-fit: contain;
                }
                .news-item-content-1{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    text-align: left;
                    height: 300px;
                    .news-subTitle {
                        color: #333333;
                        margin-bottom: 4px;
                        font-size: 16px;

                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-weight: 700;
                    }
                    .news-content{
                        color: #333333;
                        font-size: 14px;
                        height: 50px;
                        line-height: 23px;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                    }
                    .news-time{
                        font-size: 14px;
                        color: #0071c1;
                    }
                }

            }
        }
    }


    .div-main2 .news-item {
        width: 50vh;
        margin: 0 5px;
        text-align: left;
        cursor: pointer;

    }

    .div-main2 .news-item .news-subTitle {
        color: #333333;
        margin-bottom: 4px;
        font-size: 16px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 700;
    }

    .div-main2 .news-item .news-content {
        color: #333333;
        font-size: 14px;
        height: 50px;
        line-height: 23px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }

    .div-main2 .news-item .news-time {
        font-size: 14px;
        color: #0071c1;
    }

    .div-main2 .news-item .news-img {
        width: 50vh;
        height: 214px;
        border-radius: 10px;
    }

    /* 通知公告 */
    .div-main2 .notice-list .notice-item {
        /*width: 536px;*/
        background-color: #FFFFFF;
        padding: 20px;
        box-shadow: 1px 1px 5px #cccccc;
        border-radius: 5px;
        flex:1;
        margin:20px 10px;
    }

    .div-main2 .notice-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        /*justify-content: space-around;*/
        /*margin: auto;*/
        /*width: 1200px;*/
    }

    .notice-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .notice-head .h1 {
        font-size: 18px;
        color: #666666;
        font-weight: bold;
    }

    .notice-head .h2 {
        font-size: 14px;
        color: #0071C1;
        cursor: pointer;
    }

    .notice-head .h2:hover {
        color: #06558d;
    }

    .notice-subitem-body {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        color: #696171;
        font-size: 14px;
        cursor: pointer;
        text-align: left;
    }

    .notice-subitem-body .notice-cate {
        margin-right: 4px;


    }

    .notice-subitem-body:hover {
        color: #0071c1;
    }

    /* 相关服务 */
    .div-service2 {
        background-color: #f6f7f8;
        height: 220px;
        position: relative;
        overflow: hidden;
        margin-top: -6px;
    }

    .div-service2 .service-bg {
        height: 220px;
        position: relative;
        overflow: hidden;
        margin-top: -6px;
    }

    .div-service2 .service-wrap .service-itemlist {
        display: flex;
        font-size: 16px;
        align-items: center;
        justify-content: flex-start;
        /*width: 1000px;*/
        cursor: pointer;
        flex-wrap: wrap;
        /*margin-top: 20px;*/
        .item-link{
            text-decoration: none;color:#0071c1;
            width:100px;
            margin-bottom: 20px;
        }
    }

    .div-service2 .service-wrap {
        position: absolute;
        left: 10px;
        font-size: 30px;
        top: 11px;
        color: #0071C1;
        font-weight: bold;

    }

    .div-service2 .service-wrap .service-title::after {
        /*content: "|";*/
        /*margin: 0 10px;*/
    }

    /* 底部 */
    .div-footer-wrap {
        height: 152px;
        background-color: rgba(0, 43, 74, 0.8);
        margin-top: -5px;
        display: flex;
        align-items: center;
        padding: 0 70px;
    }

    .div-footer {
        display: flex;
        height: 120px;
        align-items: center;
    }

    .div-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .div-footer-desc {
        color: #D9EFFF;
        font-size: 12px;
        margin-left: 100px;
        line-height: 26px;
    }

    .div-footer .footer-weixin-title {
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
    }

    .div-footer .footer-weixin {
        height: 80px;
        width: 80px;
        background-color: rgba(211, 227, 238, 1);
    }


</style>
